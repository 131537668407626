import React from "react";
import { Segment, Container, Grid, Header, Button, List, Divider, Image } from "semantic-ui-react";
import Layout from "../../../components/Layout";
import FeaturesTable from "./features";


export const info = {
  title : 'Passwordless Authentication',
  icon : 'ban',
  subtitle: 'Get rid of passwords once and for all'
}

export { FeaturesTable };


export default () => (
  <Layout section="products" title={info.title}>
    <Segment
      basic
      style={{ margin: 0, borderRadius: 0, marginTop: "-1rem" }}
      textAlign="center"
    >
      <Container text style={{ margin: "8em" }}>
        <Header
          as="h1"
          content={info.title}
          style={{
            fontSize: "3em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "1.5em",
          }}
        />
        <Header
          as="h2"
          content={info.subtitle}
          style={{
            fontSize: "1.7em",
            fontWeight: "normal",
            marginTop: "1.5em",
          }}
        />
        <a
          href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000GE6liUAD"
          target="_blank"
        >
          <Button primary>Try now</Button>
        </a>
        <Header.Subheader style={{ color: "lightgray", margin: "8px" }}>
          Free 30 Day Trial
        </Header.Subheader>
      </Container>
    </Segment>
    <Divider />

    <Segment style={{ padding: "2em" }} vertical>
      <Container>
        <Grid columns={2} stackable>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <h1>
                Brand every pixel of the User Experience
              </h1>
              <p>Take control over each screen displayed to the user during registration, consent, multifactor authentication</p>
              <List bulleted>
                <List.Item>Authentication</List.Item>
                <List.Item>Registration</List.Item>
                <List.Item>Consent</List.Item>
                <List.Item>Multifactor authentication</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/branding.jpg" alt="Brand every screen"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

        </Grid>

        <Divider horizontal style={{ margin: "6em 0 4em" }}>
          <span>CYM Identity Features</span>
        </Divider>

        <FeaturesTable />
      </Container>
    </Segment>

  </Layout>
);
